// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---common-build-articles-list-template-js": () => import("./../../../../../common/build/articles-list/template.js" /* webpackChunkName: "component---common-build-articles-list-template-js" */),
  "component---common-build-articles-template-js": () => import("./../../../../../common/build/articles/template.js" /* webpackChunkName: "component---common-build-articles-template-js" */),
  "component---common-build-question-tags-template-js": () => import("./../../../../../common/build/questionTags/template.js" /* webpackChunkName: "component---common-build-question-tags-template-js" */),
  "component---common-build-questions-list-template-js": () => import("./../../../../../common/build/questions-list/template.js" /* webpackChunkName: "component---common-build-questions-list-template-js" */),
  "component---common-build-questions-template-js": () => import("./../../../../../common/build/questions/template.js" /* webpackChunkName: "component---common-build-questions-template-js" */),
  "component---common-build-strains-list-template-js": () => import("./../../../../../common/build/strains-list/template.js" /* webpackChunkName: "component---common-build-strains-list-template-js" */),
  "component---common-build-strains-template-js": () => import("./../../../../../common/build/strains/template.js" /* webpackChunkName: "component---common-build-strains-template-js" */),
  "component---common-build-tags-template-js": () => import("./../../../../../common/build/tags/template.js" /* webpackChunkName: "component---common-build-tags-template-js" */),
  "component---src-build-landing-page-template-js": () => import("./../../../src/build/landingPage/template.js" /* webpackChunkName: "component---src-build-landing-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-get-started-seniorcare-jsx": () => import("./../../../src/pages/get-started/seniorcare.jsx" /* webpackChunkName: "component---src-pages-get-started-seniorcare-jsx" */),
  "component---src-pages-highlights-js": () => import("./../../../src/pages/highlights.js" /* webpackChunkName: "component---src-pages-highlights-js" */),
  "component---src-pages-medical-terms-of-service-js": () => import("./../../../src/pages/medical-terms-of-service.js" /* webpackChunkName: "component---src-pages-medical-terms-of-service-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-webinars-sdm-js": () => import("./../../../src/pages/webinars-sdm.js" /* webpackChunkName: "component---src-pages-webinars-sdm-js" */)
}

